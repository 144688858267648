<!--eslint-disable vue/no-v-html-->
<template>
  <v-app-bar
    v-if="page.showToolbar"
    id="main-app-bar"
    class="nav-bar"
    :color="page.transparentToolbar ? 'transparent' : colors.bar"
    dark
    app
    clipped-center
    :flat="noScroll"
    style="overflow-y: hidden; transition: height 0.3s;"
  >
    <div
      class="nav-bar__content"
      :class="{ 'show-back-arrow': showBackArrow }"
    >
      <v-btn
        v-if="backTo && showBackArrow"
        fab
        :elevation="$vuetify.breakpoint.xsOnly ? 1 : 0"
        small
        class="mx-2 mt-1"
        :color="`${colors.bar} ${colors.arrow}--text`"
        @click="$router.push(backTo)"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div v-else-if="showBackArrow" />
      <v-toolbar-title
        v-if="true"
        class="d-flex headline align-center px-2"
        :class="$vuetify.breakpoint.smAndUp ? 'justify-center' : 'justify-start'"
        v-html="page.title"
      />
      <v-toolbar-title
        v-else
        class="px-2"
      >
        <div
          v-if="editingProjectMetadata"
          class="d-flex align-center justify-center"
          style="height: 100%;"
        >
          <v-textarea
            v-model="textFieldValue"
            style="max-width: 350px;"
            rows="1"
            hide-details
            class="ma-0 pa-0"
            light
            placeholder="Add a title"
          >
            {{ page.title }}
          </v-textarea>
          <v-btn
            class="ml-1"
            color="shamrock"
            elevation="0"
            :text="$vuetify.breakpoint.smAndUp"
            :icon="$vuetify.breakpoint.xsOnly"
            @click="handleSaveChanges"
          >
            {{ $vuetify.breakpoint.smAndUp ? 'Save' : '' }}
            <v-icon :right="$vuetify.breakpoint.smAndUp">
              mdi-check-circle-outline
            </v-icon>
          </v-btn>
          <v-btn
            class="ml-1"
            color="error"
            elevation="0"
            :text="$vuetify.breakpoint.smAndUp"
            :icon="$vuetify.breakpoint.xsOnly"
            @click="handleDiscardChanges"
          >
            {{ $vuetify.breakpoint.smAndUp ? 'Discard' : '' }}
            <v-icon :right="$vuetify.breakpoint.smAndUp">
              mdi-close
            </v-icon>
          </v-btn>
          <!-- <v-btn
            class=""
            color="metal"
            elevation="0"
            text
            @click="toggleEditingProjectMetadata"
          >
            Discard changes
            <v-icon right>
              mdi-close-circle-outline
            </v-icon>
          </v-btn> -->
        </div>
        <div
          v-else
          class="d-flex align-center justify-center"
        >
          <span>{{ page.title }}</span>
          <v-tooltip
            right
          >
            <template #activator="{ on }">
              <v-btn
                :icon="!editingProjectMetadata"
                class="ml-3 edit-button"
                color="metal"
                @click="toggleEditingProjectMetadata()"
                v-on="on"
              >
                <v-icon>
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            Edit project title and description
          </v-tooltip>
          <v-tooltip right>
            <template #activator="{ on }">
              <v-btn
                icon
                color="error"
                @click="$store.dispatch(page.deleteAction)"
                v-on="on"
              >
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
            Delete project
          </v-tooltip>
        </div>
      </v-toolbar-title>
      <div
        class="d-flex justify-end align-center"
      >
        <router-link
          v-if="page.showUserOptions && !user"
          to="/start"
        >
          <v-btn
            elevation="0"
            tile
            width="100"
            class="mx-2"
            :color="colors.bar"
          >
            Sign In
          </v-btn>
        </router-link>
        <div
          v-else
        >
          <UserSettings
            :small="$vuetify.breakpoint.smAndDown && $router.currentRoute.name === 'calculator'"
          />
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex'
import UserSettings from '@/components/application/UserSettings'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapGetters: mapProjectGetters, mapMutations: mapProjectMutations } = createNamespacedHelpers('projects')

export default {
  name: 'ToolbarTop',
  components: {
    UserSettings
  },
  data () {
    return {
      noScroll: true,
      textFieldValue: ''
    }
  },
  computed: {
    ...mapState(['page']),
    ...mapAuthGetters(['user']),
    ...mapProjectGetters(['editingProjectMetadata']),
    showBackArrow () {
      return this.$vuetify.breakpoint.smAndUp || this.page.showBackArrowOnMobile
    },
    backTo () {
      if (this.page.backButtonPath) {
        return this.page.backButtonPath
      }
      const p = this.$route.path
      const i = p.lastIndexOf('/')
      if (i === -1) return null
      return p.substr(0, i)
    },
    colors () {
      let colors = {
        bar: 'onyx white--text',
        arrow: 'primary'
      }
      switch (this.page.toolbar) {
        case 'transparent':
          colors.bar = 'transparent primary--text'
          colors.arrow = 'primary'
          break
        case 'white':
          colors.bar = 'white primary--text'
          colors.arrow = 'primary'
          break
        case 'light':
          colors.bar = 'leaf primary--text'
          colors.arrow = 'primary'
          break
        case 'dark':
        default:
          colors.bar = 'onyx white--text'
          colors.arrow = 'white'
          break
      }
      return colors
    }
  },
  watch: {
    $route (route) {
      setTimeout(() => {
        this.noScroll = document.body.scrollHeight <= document.body.clientHeight
      })
    },
    'page.title': function () {
      this.textFieldValue = this.page.title
    }
  },
  created () {
    this.textFieldValue = this.page.title
  },
  methods: {
    ...mapProjectMutations(['toggleEditingProjectMetadata']),
    handleSaveChanges () {
      if (!this.textFieldValue) {
        this.$store.dispatch('showSnackbar', 'You must enter some text to save changes')
        return
      }
      this.$store.dispatch(this.page.editableTitleAction, this.textFieldValue)
      this.toggleEditingProjectMetadata()
    },
    handleDiscardChanges () {
      this.toggleEditingProjectMetadata()
      this.textFieldValue = this.page.title
    }
  }
}
</script>

<style lang="scss">
.nav-bar {
  .v-toolbar__content {
    padding: 8px;
  }
  .nav-bar__content {
    width: 100%;
    display: grid;
    // grid-template-columns: 130px 1fr 130px;
    grid-template-columns: 1fr 10vw;
    &.show-back-arrow {
      grid-template-columns: 10vw 1fr 10vw;
    }
  }
}
</style>
