const Materials = () => import('../views/admin/materials/Materials.vue')
const MaterialForm = () => import('../views/admin/materials/MaterialForm.vue')
const MaterialCategories = () => import('../views/admin/materialCategories/MaterialCategories.vue')
const MaterialCategoryForm = () => import('../views/admin/materialCategories/MaterialCategoryForm.vue')

export default [
  {
    path: 'materials/:id',
    props: true,
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: MaterialForm
  },
  {
    path: 'materials',
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: Materials
  },
  {
    path: 'materialCategories',
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: MaterialCategories
  },
  {
    path: 'materialCategories/:id',
    props: true,
    meta: {
      requiresAuthStatus: 'admin'
    },
    component: MaterialCategoryForm
  }
]
