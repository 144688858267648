export default {
  namespaced: true,
  state: {
    allElementCategories: [],
    allIrrigationTypes: [],
    allStructuralDiversities: [],
    allMaterials: [],
    allNurseries: [],
    allPlantingTypes: [],
    allEcosystem: [],
    allEcosystemCategories: [],
    allTransportationOptions: [],
    allTransportationDistanceOptions: []
  },
  getters: {
    allElementCategories: state => state.allElementCategories,
    allIrrigationTypes: state => state.allIrrigationTypes,
    allStructuralDiversities: state => state.allStructuralDiversities,
    allMaterials: state => state.allMaterials,
    allNurseries: state => state.allNurseries,
    allPlantingTypes: state => state.allPlantingTypes,
    allEcosystem: state => state.allEcosystem,
    allEcosystemCategories: state => state.allEcosystemCategories,
    allTransportationOptions: state => state.allTransportationOptions,
    allTransportationDistanceOptions: state => state.allTransportationDistanceOptions
  },
  mutations: {
    updateLists (state, payload) {
      for (let key of Object.keys(payload)) {
        state[key] = payload[key]
      }
    }
  },
  actions: {
    async fetchLists ({ commit }) {
      let allLists = {}
      allLists.allElementCategories = (await this._vm.$axios.get(`/element_categories`)).data
      allLists.allIrrigationTypes = (await this._vm.$axios.get(`/irrigation_types`)).data
      allLists.allStructuralDiversities = (await this._vm.$axios.get(`/structural_diversities`)).data
      allLists.allMaterials = (await this._vm.$axios.get('/materials')).data
      allLists.allNurseries = (await this._vm.$axios.get('/nurseries')).data
      allLists.allPlantingTypes = (await this._vm.$axios.get(`/planting_types`)).data
      allLists.allEcosystems = (await this._vm.$axios.get(`/ecosystems`)).data
      allLists.allEcosystemCategories = (await this._vm.$axios.get(`/ecosystem_categories`)).data
      allLists.allTransportationOptions = (await this._vm.$axios.get(`/transportation_options`)).data
      allLists.allTransportationDistanceOptions = (await this._vm.$axios.get(`/transportation_distance_options`)).data
      commit('updateLists', allLists)
    }
  }
}
